import {
  IonButtons,
  IonButton,
  IonIcon,
  IonPopover,
  IonContent,
  IonDatetime,
} from "@ionic/react";
import { attach, calendar, sendOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import API from "../API";
import Input from "./Input";
import MessageBubble from "./MessageBubble";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useFilePicker } from "use-file-picker";
import { socket } from "../App";
import { setServers } from "dns";

const ChatComponent: React.FC<{
  chat: any;
}> = ({ chat }) => {
  const [messages, setMessages] = useState<Array<any>>(chat.messages);

  const audio = new Audio("/assets/tone.mp3");

  const [inputText, setInputText] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  const recorderControls = useAudioRecorder();

  const [schedulledMesage, setSchedulledMesage] = useState<string | null>(null);

  // Date popover
  const [datePopoverOpen, setDatePopoverOpen] = useState(false);
  const datePopover = useRef<HTMLIonPopoverElement>(null);
  const openDatePopover = (e: any) => {
    datePopover.current!.event = e;
    setDatePopoverOpen(e);
  };

  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: "image/*",
    readAs: "ArrayBuffer",
  });

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    socket.on("message", (msg) => {
      if (msg.event == "newMessage") {
        audio.play();
        let message = msg.data;
        if (chat && chat.id == message.chatId) {

          if (!message) {
            return;
          }
          setMessages((prev) => [...prev, message]);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (filesContent.length == 0) {
      return;
    }

    let image = filesContent[0].content as unknown;
    let base64 = btoa(
      new Uint8Array(image as ArrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    API.uploadFile(base64, "png").then(({ data }) => {
      API.sendMessage(
        chat.id,
        {
          image: data,
        },
        schedulledMesage
      ).then(({ data }) => {
        if (!data) {
          return;
        }
        setMessages((prev) => [...prev, data]);
      });
    });
  }, [filesContent]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1, overflowY: "scroll" }}>
        {messages.map((message: any) => {
          return <MessageBubble key={"msg_" + message.id} message={message} />;
        })}
        <div ref={messagesEndRef} />
      </div>

      <IonPopover
        ref={datePopover}
        isOpen={datePopoverOpen}
        onDidDismiss={() => setDatePopoverOpen(false)}
      >
        <div className="ion-padding">
          <IonButtons>
            <IonButton
              onClick={() => {
                openDatePopover(false);
              }}
            >
              Close
            </IonButton>
            <IonButton
              onClick={() => {
                openDatePopover(false);
                setSchedulledMesage(null);
              }}
            >
              Clear schedulle
            </IonButton>
          </IonButtons>
          <IonDatetime
            value={schedulledMesage}
            onIonChange={(e) => {
              setSchedulledMesage(e.detail?.value as string);
            }}
          />

          {schedulledMesage ? (
            <p>Scheduled on: {new Date(schedulledMesage!).toLocaleString()}</p>
          ) : (
            <p>Schedule not selected</p>
          )}
        </div>
      </IonPopover>

      <div
        style={{
          display: "flex",
          padding: 16,
          position: "relative",
        }}
      >
        <IonButtons>
          <IonButton
            onClick={() => {
              openDatePopover(true);
            }}
            color={schedulledMesage ? "success" : undefined}
          >
            <IonIcon icon={calendar}></IonIcon>
          </IonButton>
          <IonButton
            onClick={() => {
              openFileSelector();
            }}
          >
            <IonIcon icon={attach}></IonIcon>
          </IonButton>
        </IonButtons>

        {isRecording ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "red",
                width: 20,
                height: 20,
                borderRadius: 10,
                marginRight: 8,
              }}
            ></div>
            <p>Recording...</p>
            <IonButton
              onClick={() => {
                recorderControls.stopRecording();
              }}
            >
              Stop recording...
            </IonButton>
          </div>
        ) : (
          <Input
            style={{ flex: 1 }}
            value={inputText}
            placeholder="Enter message"
            onChange={setInputText}
          />
        )}

        <div style={{ width: 16 }}></div>
        <IonButtons>
          {inputText.length == 0 ? (
            <AudioRecorder
              onRecordingComplete={(blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  const base64data = (reader.result as string).split(",")[1];
                  API.uploadFile(base64data, "webm").then(({ data }) => {
                    API.sendMessage(
                      chat.id,
                      {
                        audio: data,
                      },
                      schedulledMesage
                    ).then(({ data }) => {
                      setIsRecording(false);

                      if (!data) {
                        return;
                      }
                      setMessages((prev) => [...prev, data]);
                    });
                  });
                };
              }}
              recorderControls={recorderControls}
            />
          ) : (
            <IonButton
              onClick={() => {
                if (inputText.length != 0) {
                  API.sendMessage(
                    chat.id,
                    {
                      text: inputText,
                    },
                    schedulledMesage
                  ).then(({ data }) => {
                    setInputText("");
                    if (!data) {
                      return;
                    }
                    setMessages((prev) => [...prev, data]);
                  });
                }
              }}
            >
              <IonIcon icon={sendOutline}></IonIcon>
            </IonButton>
          )}
        </IonButtons>
      </div>
    </div>
  );
};

export default ChatComponent;

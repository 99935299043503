const forceProd = true;

const isLocalhost =
  document.location.hostname == "localhost" ||
  document.location.hostname.startsWith("192.168.");

export const host =
  isLocalhost && !forceProd
    ? `http://${document.location.hostname}:10200/`
    : "https://api.chillbetapp.top/";

export const socketAddress =
  isLocalhost && !forceProd
    ? `http://${document.location.hostname}:10201/`
    : "https://ws.chillbetapp.top/";

export const serverAddress = `${host}api/`;
export const publicAddress = `${host}public/`;

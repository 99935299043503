import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import API from "../API";
import ChatComponent from "../components/ChatComponent";



const ChannelPage: React.FC = () => {

    const [chat, setChat] = useState<any | null>(null)

    useEffect(() => {
        API.getChannelChat().then(({data}) => {
            setChat(data);
        })
    }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Channel</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {chat == null ? <IonSpinner/> : <ChatComponent chat={chat} /> } 
      </IonContent>
    </IonPage>
  );
};

export default ChannelPage;

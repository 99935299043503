import { publicAddress } from "../Constants";
import "./MessageBubble.css";

const MessageBubble: React.FC<{
  message: any;
}> = ({ message }) => {
  const messageContent = () => {
    if (message.text) {
      return <p>{message.text}</p>;
    }
    if (message.image) {
      return <img width={200} src={publicAddress + message.image} />;
    }
    if (message.audio) {
      return (
        <audio controls>
          <source src={publicAddress + message.audio} />
        </audio>
      );
    }
    return <p>Unsupported message</p>;
  };

  return (
    <div
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        width: "100%",
        display: "flex",
        justifyContent: message.messageDirection == "toAdmin" ? "start" : "end",
      }}
    >
      <div className="bubble">
        {messageContent()}
        <p style={{textAlign: 'end'}} >{new Date(message.createdAt).toLocaleTimeString()}</p>
      </div>
    </div>
  );
};

export default MessageBubble;

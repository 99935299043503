import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import API from "../API";

const StreamsPage: React.FC = () => {
  const [streams, setStreams] = useState<Array<any> | null>(null);

  useEffect(() => {
    API.getStreams().then(({ data }) => {
      setStreams(data);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Users</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/streams/create" >Create</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {streams == null ? (
          <IonSpinner />
        ) : (
          <IonList>
            {streams.map((s) => {
              return (
                <IonItem routerLink={'/streams/' + s.id} key={"stream_" + s.id}>
                  <IonLabel>
                    <h2>
                      [ID: {s.id}] {s.name}
                    </h2>
                    <p>Users count: {s.usersCount}</p>
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StreamsPage;

import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import MainPage from "./pages/MainPage";
import UserService from "./UserService";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router";
import AuthPage from "./pages/AuthPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { useEffect, useState } from "react";
import { socketAddress } from "./Constants";
import React from "react";

setupIonicReact({ mode: "md" });

export const socket = io(socketAddress, {
  auth: {
    token: UserService.getToken(),
  },
});

const App: React.FC = () => {
  useEffect(() => {
    socket.on("connect", () => {});

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  useEffect(() => {
    socket.on("logMessage", (message) => {
      toast.info(message.data);
    });
  }, []);

  return (
    <React.StrictMode>
      <ToastContainer />
      <IonApp>
        {UserService.isLogged() ? (
          <MainPage />
        ) : (
          <IonReactRouter>
            <IonRouterOutlet id="main">
              <Route path="/auth" component={AuthPage} exact />
              <Redirect from="/" to="/auth" exact />
            </IonRouterOutlet>
          </IonReactRouter>
        )}
      </IonApp>
    </React.StrictMode>
  );
};

export default App;

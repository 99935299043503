import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFilePicker } from "use-file-picker";
import API from "../API";
import ChatComponent from "../components/ChatComponent";
import Input from "../components/Input";
import { publicAddress } from "../Constants";

const CreateStreamPage: React.FC = () => {
  const [logo, setLogo] = useState("");
  const [appName, setAppName] = useState("");

  const history = useHistory();

  const [streamCreating, setStreamCreating] = useState(false);

  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: "image/*",
    readAs: "ArrayBuffer",
  });

  useEffect(() => {
    if (filesContent.length == 0) {
      return;
    }

    let image = filesContent[0].content as unknown;
    let base64 = btoa(
      new Uint8Array(image as ArrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    API.uploadFile(base64, "png").then(({ data }) => {
      setLogo(data);
    });
  }, [filesContent]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/streams"></IonBackButton>
          </IonButtons>
          <IonTitle>Create stream</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <IonItem>
              {logo.length == 0 ? null : (
                <IonAvatar slot="start">
                  <img src={publicAddress + logo} />
                </IonAvatar>
              )}
              <IonLabel>
                <h2>App logo</h2>
              </IonLabel>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    openFileSelector();
                  }}
                >
                  Upload
                </IonButton>
              </IonButtons>
            </IonItem>

            <IonInput>
              <Input
                style={{ width: "100%" }}
                onChange={setAppName}
                value={appName}
                placeholder="App name"
              />
            </IonInput>

            <br />

            <IonButton
              onClick={() => {


                if (appName.length == 0) {
                  return alert("Enter app name");
                }
                if (logo.length == 0) {
                  return alert("Upload a logo");
                }

                if (streamCreating) {
                    return;
                  }
                  setStreamCreating(true);
                API.createStream(appName, logo).then(({ data }) => {
                  const stream = data;
                  console.log(stream);
                  setStreamCreating(false);

                  setLogo("");
                  setAppName("");
                  history.push("/streams/" + stream.id);
                  
                }).catch((err) => {
                    setStreamCreating(false);
                });
              }}
            >
              {streamCreating ? <IonSpinner /> : "Create"}
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default CreateStreamPage;

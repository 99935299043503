import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import API from "../API";
import Input from "../components/Input";
import Push from "../components/Push";

const PushNotificationsPage: React.FC = () => {
  const [history, setHistory] = useState<Array<any> | null>(null);

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    API.getPushHistory().then(({ data }) => {
      setHistory(data);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Push</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {history == null ? (
          <IonSpinner />
        ) : (
          <div>
            <IonCard>
              <IonCardContent>
                <h2>Create new PUSH</h2>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Input
                      placeholder="Title"
                      value={title}
                      onChange={setTitle}
                    />
                    <Input
                      placeholder="Message"
                      value={message}
                      onChange={setMessage}
                    />
                  </div>
                  <IonButtons>
                    <IonButton
                      onClick={() => {
                        API.sendPush(title, message).then(() => {
                          setTitle("");
                          setMessage("");
                          API.getPushHistory().then(({ data }) => {
                            setHistory(data);
                          });
                        });
                      }}
                      color={"success"}
                    >
                      Send
                    </IonButton>
                  </IonButtons>
                </div>
              </IonCardContent>
            </IonCard>
            <IonList>
              {history.map((h) => {
                return <Push onUpdated={setHistory} key={"push_" + h.id} push={h} />;
              })}
            </IonList>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PushNotificationsPage;

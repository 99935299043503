import {
  IonRouterOutlet,
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { gitPullRequest, megaphone, person, pushOutline } from "ionicons/icons";
import { Redirect, Route } from "react-router";
import { Page } from "../interfaces/Page";
import ChannelPage from "./ChannelPage";
import ChooseStreamPage from "./ChooseStreamPage";
import CreateStreamPage from "./CreateStreamPage";
import PushNotificationsPage from "./PushNotificationsPage";
import StreamsPage from "./StreamsPage";

const PAGES: Array<Page> = [
  {
    name: "Streams",
    route: "/streams",
    page: StreamsPage,
    icon: gitPullRequest,
  },
  {
    name: "Channel",
    route: "/channel",
    page: ChannelPage,
    icon: megaphone,
  },
  {
    name: "Push",
    route: "/push",
    page: PushNotificationsPage,
    icon: pushOutline,
  },
];

const MainPage: React.FC = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet id="main">
        <Redirect from="/" to={PAGES[0].route} exact />
      </IonRouterOutlet>
      <IonTabs>
        <IonRouterOutlet>
          {PAGES.map((page) => {
            return (
              <Route
                key={"route_" + page.route}
                exact
                path={page.route}
                component={page.page}
              />
            );
          })}

          <Route exact path={"/streams/:id"} component={ChooseStreamPage} />
          <Route exact path={"/streams/create"} component={CreateStreamPage} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          {PAGES.map((page, index) => {
            return (
              <IonTabButton
                key={"tab_button_" + index}
                tab={"tab_" + index}
                href={page.route}
              >
                <IonIcon icon={page.icon} />
                <IonLabel>{page.name}</IonLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
};

export default MainPage;

import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { refreshCircle } from "ionicons/icons";
import API from "../API";

const Push: React.FC<{
  push: any;
  onUpdated: (messages: Array<any>) => void;
}> = ({ push, onUpdated }) => {
  return (
    <div
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        width: "100%",
        display: "flex",
      }}
    >
      <div style={{ flex: 1 }}>
        <p>Title: {push.title}</p>
        <p>Message: {push.message}</p>
      </div>

      <div style={{ flex: 1 }}>
        <p>Recipients: {push.recipients}</p>
        <p>Received: {push.received}</p>
        <p>Successful: {push.successful}</p>
        <p>Failed: {push.failed}</p>
        <p>Errored: {push.errored}</p>
        <p>Converted: {push.converted}</p>
        <p>Sended: {new Date(push.createdAt).toLocaleString()}</p>
      </div>

      <div style={{ flex: 1 }}>
        <IonButtons>
          <IonButton
            onClick={() => {
              API.pushUpdateStats(push.id).then(({ data }) => {
                onUpdated(data);
              });
            }}
            color="success"
          >
            Update&nbsp;<IonIcon icon={refreshCircle}></IonIcon>
          </IonButton>
        </IonButtons>
      </div>
    </div>
  );
};

export default Push;

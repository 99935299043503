import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import API from "../API";
import ChatComponent from "../components/ChatComponent";
import { publicAddress } from "../Constants";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

const ChooseStreamPage: React.FC<Props> = ({ match }) => {
  const [stream, setStream] = useState<null | any>(null);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [chat, setChat] = useState<any | null>(null);

  useEffect(() => {
    API.getStreamInfo(parseInt(match.params.id)).then(({ data }) => {
      setStream(data);
    });
  }, []);

  useEffect(() => {
    if (selectedUser == null) {
      return;
    }

    API.getChatWithUser(selectedUser.id).then(({ data }) => {
      setChat(data);
    });
  }, [selectedUser]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/streams"></IonBackButton>
          </IonButtons>
          <IonTitle>Stream #{match.params.id}</IonTitle>

          <IonButtons slot="end">
            {stream?.apk ? (
              <IonButton
                onClick={() => {
                  window.open(publicAddress + stream.apk);
                }}
              >
                Download APK
              </IonButton>
            ) : (
              false
            )}
            {stream?.compileError ? (
              <IonButton
                onClick={() => {
                  alert(stream.compileError);
                }}
              >
                Open compile error
              </IonButton>
            ) : (
              false
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {stream == null ? (
          <IonSpinner />
        ) : (
          <div style={{ display: "flex", height: "100%" }}>
            <IonList style={{ width: 300 }}>
              {stream.users.map((user: any) => {
                return (
                  <IonItem
                    onClick={() => {
                      setSelectedUser(user);
                      setChat(null);
                    }}
                    style={{
                      "--background":
                        selectedUser?.id == user.id ? "#aeaeae" : undefined,
                    }}
                    key={"user_chat_" + user.id}
                  >
                    <IonAvatar slot="start">
                      {user.avatar ? (
                        <img src={publicAddress + user.avatar}></img>
                      ) : (
                        <img src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                      )}
                    </IonAvatar>
                    <IonLabel>{user.name ?? "No name"}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
            <div
              style={{ height: "100%", width: 1, background: "#3e3e3e" }}
            ></div>
            <div style={{ flex: 1 }}>
              {chat == null ? (
                <p>Select user for open chat</p>
              ) : (
                <ChatComponent chat={chat} />
              )}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChooseStreamPage;

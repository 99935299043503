import { IonButton, IonIcon, IonPage, useIonLoading } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import API from "../API";
import Input from "../components/Input";
import UserService from "../UserService";

const AuthPage: React.FC = () => {
  const [present, dismiss] = useIonLoading();

  const history = useHistory();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  return (
    <IonPage>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <div style={{ width: "80%", textAlign: "center" }}>
          <h2>Welcome to Admin panel</h2>

          <Input placeholder={"Login"} value={login} onChange={setLogin} />
          <Input
            placeholder={"Password"}
            value={password}
            onChange={setPassword}
          />

          <IonButton
            onClick={async () => {
              let res = await API.auth(login, password);
              UserService.setAccessToken(res.data.token);
              window.location.href = "/";
            }}
          >
            Auth
          </IonButton>
        </div>
      </div>
    </IonPage>
  );
};

export default AuthPage;

import { toast } from "react-toastify";
import { serverAddress } from "./Constants";
import UserService from "./UserService";

export default class {
  static headers = { "Content-type": "application/json" };

  static errorMiddleware(request: any) {
    if (request.success == false) {
      if (request.code == 1) {
        UserService.dropUserData();
        alert("Неккоректный токен. Нужно переавторизоваться");
        document.location.href = "/auth";
      } else {
        toast.error(
          typeof request.error == "string"
            ? request.error
            : JSON.stringify(request.error)
        );
        throw request.error;
      }
    }
    return request;
  }

  static getBody(data: any = {}) {
    if (UserService.isLogged()) {
      return JSON.stringify({
        token: UserService.getToken(),
        ...data,
      });
    } else {
      return JSON.stringify({
        ...data,
      });
    }
  }

  static async auth(login: string, password: string) {
    let responce = await fetch(serverAddress + "admin/auth", {
      method: "POST",
      body: this.getBody({
        login: login,
        password: password,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getStreams() {
    let responce = await fetch(serverAddress + "admin/streams/list", {
      method: "POST",
      body: this.getBody({}),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async createStream(name: string, logo: string) {
    let responce = await fetch(serverAddress + "admin/streams/create", {
      method: "POST",
      body: this.getBody({
        name,
        logo,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getStreamInfo(id: number) {
    let responce = await fetch(serverAddress + "admin/streams/info", {
      method: "POST",
      body: this.getBody({ id }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getChatWithUser(userId: number) {
    let responce = await fetch(serverAddress + "admin/chat/get", {
      method: "POST",
      body: this.getBody({ userId }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async sendMessage(chatId: number, messageData: any, schedule: string | null) {
    if (schedule) {
      let responce = await fetch(serverAddress + "admin/chat/sendLateMessage", {
        method: "POST",
        body: this.getBody({ chatId, ...messageData, schedule }),
        headers: this.headers,
      });
  
      return this.errorMiddleware(await responce.json());
    }else{
      let responce = await fetch(serverAddress + "admin/chat/sendMessage", {
        method: "POST",
        body: this.getBody({ chatId, ...messageData }),
        headers: this.headers,
      });
  
      return this.errorMiddleware(await responce.json());
    }
    
  }

  static async uploadFile(base64: string, filetype: string) {
    let responce = await fetch(serverAddress + "shared/upload", {
      method: "POST",
      body: this.getBody({ data: base64, filetype: filetype }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getChannelChat() {
    let responce = await fetch(serverAddress + "admin/channel/get", {
      method: "POST",
      body: this.getBody({}),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getPushHistory() {
    let responce = await fetch(serverAddress + "admin/push/history", {
      method: "POST",
      body: this.getBody({}),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async pushUpdateStats(id: number) {
    let responce = await fetch(serverAddress + "admin/push/updateStats", {
      method: "POST",
      body: this.getBody({ id }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async sendPush(title: string, message: string) {
    let responce = await fetch(serverAddress + "admin/push/send", {
      method: "POST",
      body: this.getBody({ title, message }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }
}

import { IonIcon, IonTextarea } from "@ionic/react";
import { CSSProperties } from "react";

import "./Input.css";

const Input: React.FC<{
  title?: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: CSSProperties | undefined;
  icon?: string;
}> = ({ title, placeholder, value, onChange, style, icon }) => {
  return (
    <div style={{ marginTop: 8, ...style }} id="container">
      {title ? <p className="title">{title}</p> : false}

      <div className="content">
        {icon ? (
          <IonIcon icon={icon} style={{ marginLeft: 8, marginRight: 8 }} />
        ) : (
          false
        )}
        <input
          value={value}
          onChange={(e) => onChange(e.target.value ?? "")}
          placeholder={placeholder}
        ></input>
      </div>
    </div>
  );
};

export default Input;
